<template>
  <div>
    <AddressWidgetSendContract
      class="mb-3"
      style="padding-right: 30px; margin: auto; width: fit-content"
      :address-id="address.addressId.toString()"
      :first-line="address.add1"
      :second-line="address.add2"
      :town="address.town"
      :county="address.county"
      :post-code="address.postcode"
      :start-date="address.fromDate"
      :end-date="address.untilDate"
      showEdit
      no-actions
      @editAddressClickedToggle="editAddressClicked($event, address.addressId)"
      @deleteAddressClicked="deleteAddressClicked($event, address.addressId)"
    ></AddressWidgetSendContract>
    <CreditInformationSendContract
      v-show="showCreditInfo"
      v-on:selected-address-id="selectedAddressIdChanged"
      class="mt-4"
      :contact-id="selectedContact.contactId"
      :editClicked="selectedAddressId"
      :deleteAddressId="deleteAddressId"
      :editAddressId="selectedAddressId"
      @new-address-added="fetchContactAddresses(selectedContact.contactId)"
    >
    </CreditInformationSendContract>
  </div>
</template>

<script>
import AddressWidgetSendContract from "@/components/forms/send-contract/address_widget_send_contract"

import CreditInformationSendContract from "@/components/forms/send-contract/credit_info_send_contract.vue"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "AdressAndCreditInformation",
  components: { CreditInformationSendContract, AddressWidgetSendContract },

  data: () => ({
    selectedAddressId: null,
    deleteAddressId: null,

    showCreditInfo: false
  }),

  props: {
    address: {
      type: Object
    },
    lastOne: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("contacts", ["contactAddressItems", "selectedContact"])
  },
  //   mounted() {
  //     this.showCreditInfo = this.showNewAddressInformation
  //   },
  watch: {
    // showNewAddressInformation(newVal) {
    //   this.showCreditInfo = newVal
    // },
  },
  methods: {
    ...mapActions("contacts", ["fetchContactAddresses"]),
    selectedAddressIdChanged(value) {
      this.contactAddressItems.addressId = value
      this.selectedAddressId = value
    },
    editAddressClicked(toggleValue, id) {
      this.showCreditInfo = toggleValue
      if (this.lastOne && toggleValue) {
        this.$emit("lastOneEditShowClicked", false)
      } else if (this.lastOne && !toggleValue) {
        this.$emit("lastOneEditShowClicked", true)
      }
      this.selectedAddressId = id
    },
    deleteAddressClicked(toggleValue, id) {
      this.showCreditInfo = toggleValue

      if (this.lastOne && toggleValue) {
        this.$emit("lastOneDeleteClicked", false)
      } else if (this.lastOne && !toggleValue) {
        this.$emit("lastOneDeleteClicked", true)
      }
      this.deleteAddressId = id
    }
  }
}
</script>
